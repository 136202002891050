<template lang="html">
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12">
        <v-encounter-bar @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
      <v-col cols="12" v-if="currentEncounter">
        <v-encounter-transaction :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId"></v-encounter-transaction>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'

export default {
  mixins: [encounterLoader],
}
</script>

<style lang="css" scoped>
</style>
